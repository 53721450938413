<mat-tab-group>
	<mat-tab>
		<ng-template mat-tab-label>
			<mat-icon>calculate</mat-icon>
			<div class="px-2">{{ "tools.formulas.label" | translate }}</div>
		</ng-template>
		<app-formulas></app-formulas>
	</mat-tab>
	<mat-tab>
		<ng-template mat-tab-label>
			<mat-icon>scoreboard</mat-icon>
			<div class="px-2">{{ "tools.scores.label" | translate }}</div>
		</ng-template>
		<ng-template matTabContent>
			<app-scores></app-scores>
		</ng-template>
	</mat-tab>
	<mat-tab>
		<ng-template mat-tab-label>
			<mat-icon>heat_pump</mat-icon>
			<div class="px-2">{{ "tools.tool.obs-wheel.label" | translate }}</div>
		</ng-template>
		<ng-template matTabContent>
			<app-obstetric-wheel></app-obstetric-wheel>
		</ng-template>
	</mat-tab>
</mat-tab-group>
