import { Component } from "@angular/core";
import { FormulaViewerComponent } from "../../../core/formula-viewer/formula-viewer.component";
import { MatCard, MatCardContent, MatCardTitle } from "@angular/material/card";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { MatFormField, MatLabel, MatPrefix } from "@angular/material/form-field";
import { MatOption, MatSelect } from "@angular/material/select";
import { MatIcon } from "@angular/material/icon";
import { ScoreService } from "../../../../services/score.service";
import { Score } from "../../../../entities/tools/score/score";
import { ScoreViewerComponent } from "../score-viewer/score-viewer.component";
import { MatFabButton } from "@angular/material/button";
import { NgIf } from "@angular/common";
import { MitpManagerService } from "../../../../services/mitp-manager.service";
import { MatDialog } from "@angular/material/dialog";
import { CreateScoreComponent } from "../create-score/create-score.component";
import { first } from "rxjs";

@Component({
	selector: "app-scores",
	standalone: true,
	imports: [
		FormulaViewerComponent,
		MatCard,
		MatCardTitle,
		NgxTolgeeModule,
		MatCardContent,
		MatFormField,
		MatSelect,
		MatIcon,
		MatOption,
		MatLabel,
		ScoreViewerComponent,
		MatFabButton,
		NgIf,
		MatPrefix,
	],
	templateUrl: "./scores.component.html",
	styleUrl: "./scores.component.scss",
})
export class ScoresComponent {
	selectedScore: Score = null;
	scores: Score[] = [];

	constructor(
		public scoreService: ScoreService,
		public manager: MitpManagerService,
		private dialog: MatDialog
	) {
		this.scoreService
			.getScores()
			.pipe(first())
			.subscribe((scores) => {
				this.scores = scores;
			});
	}

	createScore(): void {
		this.dialog.open(CreateScoreComponent, {
			data: { score: null },
			width: "600px",
			disableClose: true,
			autoFocus: false,
		});
	}

	editScore(): void {
		this.dialog.open(CreateScoreComponent, {
			data: { score: this.selectedScore },
			width: "600px",
			disableClose: true,
			autoFocus: false,
		});
	}
}
