<div class="flex flex-col">
	<mat-card
		appearance="outlined"
		style="margin: 32px">
		<mat-card-title>
			<h2>
				{{ "tools.score.select" | translate }}
			</h2>
		</mat-card-title>
		<mat-card-content class="flex flex-col">
			<mat-form-field
				appearance="outline"
				class="w-full">
				<mat-label>{{ "tools.scores.label" | translate }}</mat-label>
				<mat-select [(value)]="selectedScore">
					@for (item of scores; track item.id) {
						<mat-option [value]="item">{{ item.label }}</mat-option>
					}
				</mat-select>
				<mat-icon matIconPrefix>scoreboard</mat-icon>
			</mat-form-field>
			<button
				mat-fab
				color="primary"
				(click)="createScore()"
				aria-label=""
				style="position: absolute; right: 40px; bottom: -25px; z-index: 99"
				*ngIf="manager.isAdmin()">
				<mat-icon>add</mat-icon>
			</button>
			<button
				mat-fab
				color="primary"
				(click)="editScore()"
				aria-label=""
				style="position: absolute; right: 120px; bottom: -25px; z-index: 99"
				*ngIf="manager.isAdmin() && selectedScore">
				<mat-icon>edit</mat-icon>
			</button>
		</mat-card-content>
	</mat-card>
	<app-score-viewer
		*ngIf="selectedScore"
		style="margin-right: 32px; margin-left: 32px"
		[selectedScore]="selectedScore"></app-score-viewer>
</div>
