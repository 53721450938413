import { ScoreCriterion } from "./score-criterion";
import { ScoreReference } from "./score-reference";

export class Score {
	id: number;
	label: string;
	criteria: ScoreCriterion[];
	last_updated: Date;
	reference: ScoreReference[];
	description: string;
}
