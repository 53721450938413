import { Component, Input } from "@angular/core";
import { Score } from "../../../../entities/tools/score/score";
import { MatDivider } from "@angular/material/divider";
import { NgIf } from "@angular/common";
import { MatRadioButton, MatRadioChange, MatRadioGroup } from "@angular/material/radio";
import { ScoreCriterion } from "../../../../entities/tools/score/score-criterion";

@Component({
	selector: "app-score-viewer",
	standalone: true,
	imports: [MatDivider, NgIf, MatRadioButton, MatRadioGroup],
	templateUrl: "./score-viewer.component.html",
	styleUrl: "./score-viewer.component.scss",
})
export class ScoreViewerComponent {
	_selectedScore: Score;
	values = new Map<number, number>();
	result: number = null;

	@Input() set selectedScore(score: Score) {
		this._selectedScore = score;
		this.onSelectedScoreChange();
	}

	private onSelectedScoreChange(): void {
		this.result = null;
		this.values.clear();
	}

	calculate($event: MatRadioChange, criterion: ScoreCriterion): void {
		this.values.set(criterion.id, $event.value);
		if (this.values.size === this._selectedScore.criteria.length) {
			this.result = Array.from(this.values.values()).reduce((a, b) => a + b, 0);
		}
	}
}
