import { Component } from "@angular/core";
import { MatTab, MatTabContent, MatTabGroup, MatTabLabel } from "@angular/material/tabs";
import { ObstetricWheelComponent } from "../obstetric-wheel/obstetric-wheel/obstetric-wheel.component";
import { FormulasModule } from "../../formulas/formulas.module";
import { MatIcon } from "@angular/material/icon";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { ScoresComponent } from "../score/scores/scores.component";

@Component({
	selector: "app-tools",
	standalone: true,
	imports: [
		MatTabGroup,
		MatTab,
		ObstetricWheelComponent,
		FormulasModule,
		MatIcon,
		MatTabLabel,
		NgxTolgeeModule,
		ScoresComponent,
		MatTabContent,
	],
	templateUrl: "./tools.component.html",
	styleUrl: "./tools.component.scss",
})
export class ToolsComponent {}
