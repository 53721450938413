<div mat-dialog-title>{{ "tools.score.reference.modal.title" | translate }}</div>
<p></p>
<div mat-dialog-content>
	<div class="flex flex-col">
		<mat-form-field appearance="outline">
			<mat-label>{{ "tools.score.reference.label" | translate }}</mat-label>
			<input
				matInput
				[(ngModel)]="reference.label"
				required />
			<mat-icon matIconPrefix>label</mat-icon>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>{{ "tools.score.reference.url" | translate }}</mat-label>
			<input
				matInput
				[(ngModel)]="reference.url" />
			<mat-icon matIconPrefix>link</mat-icon>
		</mat-form-field>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.cancel" | translate }}
	</button>
	<mat-spinner
		color="primary"
		diameter="40"
		*ngIf="isLoading"></mat-spinner>
	<button
		*ngIf="!isLoading"
		mat-raised-button
		color="primary"
		(click)="add()">
		{{ "core.add" | translate }}
	</button>
</div>
