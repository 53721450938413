import { ScoreOption } from "./score-option";

export class ScoreCriterion {
	id: number;
	label: string;
	options: ScoreOption[];

	constructor() {
		this.options = [];
	}
}
