import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { MITPService } from "./mitp.service";
import { Score } from "../entities/tools/score/score";
import { Observable } from "rxjs";
import { ScoreReference } from "../entities/tools/score/score-reference";
import { ScoreCriterion } from "../entities/tools/score/score-criterion";

@Injectable({
	providedIn: "root",
})
export class ScoreService {
	constructor(
		private http: HttpClient,
		private mitp: MITPService
	) {}

	getScores(): Observable<Score[]> {
		return this.http.get<Score[]>(this.mitp.Scores());
	}

	addReference(score: Score, reference: ScoreReference): Observable<ScoreReference> {
		return this.http.post<ScoreReference>(this.mitp.Scores() + `/${score.id}/references`, reference);
	}

	create(score: Score): Observable<Score> {
		return this.http.post<Score>(this.mitp.Scores(), score);
	}

	update(score: Score): Observable<Score> {
		return this.http.post<Score>(this.mitp.Scores() + `/${score.id}`, score);
	}

	deleteReference(score: Score, reference: ScoreReference): Observable<HttpResponse<null>> {
		return this.http.delete<null>(this.mitp.Scores() + `/${score.id}/references/${reference.id}`, {
			observe: "response",
		});
	}

	createScoreCriterion(score: Score, criterion: ScoreCriterion): Observable<Score> {
		return this.http.post<Score>(this.mitp.Scores() + `/${score.id}/criteria`, criterion);
	}

	deleteCriterion(score: Score, criterion: ScoreCriterion): Observable<HttpResponse<null>> {
		return this.http.delete<null>(this.mitp.Scores() + `/${score.id}/criteria/${criterion.id}`, {
			observe: "response",
		});
	}
}
