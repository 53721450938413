<div class="flex flex-col items-center">
	<div class="font-bold text-xl text-blue-500">{{ _selectedScore.label }}</div>
	<div class="grid grid-cols-2 gap-y-3">
		@for (criterion of _selectedScore.criteria; track criterion; let last = $last) {
			<div class="flex flex-col items-end justify-center px-8 font-bold">
				{{ criterion.label }}
			</div>
			<div class="flex flex-col mitp-border-score">
				<mat-radio-group (change)="calculate($event, criterion)">
					@for (option of criterion.options; track option; let clast = $last) {
						<mat-radio-button [value]="option.value">
							<div class="flex flex-row items-center gap-x-2">
								<div>{{ option.label }}</div>
								<div>({{ option.value }})</div>
							</div>
						</mat-radio-button>
						<mat-divider *ngIf="!clast"></mat-divider>
					}
				</mat-radio-group>
			</div>
		}
	</div>
	<div class="flex flex-row items-center gap-x-2 mt-8 text-lg">
		<div class="font-bold">Résultat :</div>
		<div class="text-blue-500">{{ result ?? "-" }}</div>
	</div>
	<div class="flex flex-row w-full items-start my-4">
		{{ _selectedScore.description }}
	</div>
	<div class="flex flex-col items-start w-full italic">
		<div class="font-bold">Références : <span *ngIf="_selectedScore.reference.length === 0">-</span></div>
		<ul>
			@for (reference of _selectedScore.reference; track reference) {
				<li *ngIf="reference.url; else noLink">
					<a
						[href]="reference.url"
						target="_blank">
						{{ reference.label }}
					</a>
				</li>
				<ng-template #noLink>
					<li>{{ reference.label }}</li>
				</ng-template>
			}
		</ul>
	</div>
</div>
